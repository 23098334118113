import reactHook from "../images/react-hooks.avif"
export const NAVBAR_MENUS = {
  menuItems: [
    {
      label: "Home",
      link: "/",
    },
    {
      label: "Products",
      link: " ",
      subMenu: [
        {
          label: "Migobucks App",
          link: "https://www.migobucks.com/",
        },
        {
          label: "Migo Buddy",
          link: "https://www.migobucks.com/buddy",
        },
        {
          label: "Migo Brand",
          link: "https://www.brand.migobucks.com/",
        },
      ],
    },
    {
      label: "Solutions",
      link: "",
      subMenu: [
        {
          label: "Migobucks",
          link: "https://www.migobucks.com/",
        },
        {
          label: "Sokoskills",
          link: "https://www.sokoskills.com/",
        },
        {
          label: "Zemwa",
          link: "https://mb.migobucks.tech/",
        },
      ],
    },

    {
      label: "Careers",
      link: "/careers",
    },
    {
      label: "Contact",
      link: "/contact",
    },
    {
      label: "About US",
      link: "/about",
    },
  ],
}

export const SERVICES = [
  {
    title: "Enterprise and Cloud Architecture",
    order: 1,
  },
  {
    title: "Web and Mobile App Modernization",
    order: 2,
  },
  {
    title: "E-Commerce",
    order: 3,
  },
  {
    title: "Project Discovery and Scoping Phase",
    order: 4,
  },
]

export const CAREER_LIST = [
  {
    title: "Flutter Developer",
    description:
      "We are looking for an experienced Flutter Developer with 2-3 years of experience.",
    openPositions: 3,
    location: "Kochi, India",
    remote: "Yes (Full Work-From-Home)",
    salary: "As Per Industry Standard",
    experience: "2-3 years",
    techStack: "Flutter, Dart, Firebase",
    url: "https://www.migobucks.com/professionals",
  },
  {
    title: "React Developer",
    description:
      "Join our team as a React Developer with 2-3 years of experience.",
    openPositions: 2,
    location: "Kochi, India",
    remote: "Yes (Full Work-From-Home)",
    salary: "As Per Industry Standard",
    experience: "2-3 years",
    techStack: "React, JavaScript, Redux",
    url: "https://www.migobucks.com/professionals",
  },
  {
    title: "AWS Developer",
    description:
      "Are you an AWS Developer with 2-3 years of experience? Check out our opening.",
    openPositions: 4,
    location: "Kochi, India",
    remote: "Yes (Full Work-From-Home)",
    salary: "As Per Industry Standard",
    experience: "2-3 years",
    techStack: "AWS, Lambda, S3",
    url: "https://www.migobucks.com/professionals",
  },
  {
    title: "Digital Marketing Executive",
    description:
      "Join our team as a Digital Marketing Executive with 2-3 years of experience.",
    openPositions: 2,
    location: "Kochi, India",
    remote: "Yes (Full Work-From-Home)",
    salary: "As Per Industry Standard",
    experience: "2-3 years",
    techStack: "SEO, SEM, Social Media Marketing",
    url: "https://www.migobucks.com/professionals",
  },
  {
    title: "Interns",
    description:
      "We have internship opportunities available in all the mentioned departments.",
    openPositions: 5,
    location: "Various Locations (Full Work-From-Home)",
    remote: "Yes",
    salary: "Stipend",
    experience: "Not required",
    techStack: "Varies by department",
    url: "https://www.migobucks.com/ojt",
  },
  {
    title: "Business Analysts (BAs)",
    description: "We are hiring Business Analysts (BAs) to join our team.",
    openPositions: 2,
    location: "Kochi, India",
    remote: "Yes (Full Work-From-Home)",
    salary: "As Per Industry Standard",
    experience: "1-2 years",
    techStack: "Business Analysis Tools",
    url: "https://www.migobucks.com/professionals",
  },
]

export const BLOG_POSTS = [
  {
    title: "The Power of React Hooks",
    imageUrl: reactHook,
    description:
      "Exploring the benefits and usage of React Hooks in modern web development.",
    url: "https://www.sokoskills.com/blog/react-hooks",
  },
  {
    title: "The-Crucial-Role-of-Business-Analysts-in-Modern-Organizations",
    imageUrl:
      "https://www.sokoskills.com/store/1/Course%20Pictures/Business%20Analyst/ba.jpg",
    description:
      "Business analysts play a pivotal role in modern organizations by bridging the gap between business needs and technological solutions. They ensure efficient workflows, data-driven decision-making, and streamlined processes, making them indispensable contributors to business success.",
    url:
      "https://www.sokoskills.com/blog/The-Crucial-Role-of-Business-Analysts-in-Modern-Organizations",
  },
  {
    title: "Node.js and Express for Backend Development",
    imageUrl:
      "https://images.unsplash.com/photo-1620034949504-339c43e9cd56?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80",
    description:
      "Building robust and scalable APIs with Node.js and Express.js for backend development.",
    url: "https://www.sokoskills.com/blog/nodejs-express-backend",
  },
]
