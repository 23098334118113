import { Link } from "gatsby"
import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaTwitter,
  FaWhatsapp,
} from "react-icons/fa"
import {
  faCoffee,
  faAddressBook,
  faAirFreshener,
  faAmbulance,
  faAtom,
  faBus,
  faCoins,
  faDice,
  facebook,
  faInstagram,
} from "@fortawesome/free-solid-svg-icons"
import logo from "../images/logo.svg"
import Facebook from "../images/facebook.svg"

const Footer = () => {
  return (
    <>
      <hr className="my-4" />
      <footer className="bg-black text-white my-10">
        <div className="flex items-center justify-around">
          <div className="flex flex-col">
            <div>
              <img src={logo} alt="Logo" />
            </div>

            <div style={{ paddingTop: "10px" }}>
              <p className="text-white text-sm">
                <a href="mailto:info@dv.com" className="text-white">
                  hello@migobucks.com
                </a>
              </p>
            </div>
            <p className="text-white text-sm">Mob: 7090070200, 9037243199</p>
            <p className="text-white text-md">
              &copy; 2023 Migobucks Technologies
            </p>
            <h6 className="text-white text-sm font-semibold mb-1 my-4 py-2">
              FOLLOW US ON :
            </h6>
            <div className="flex">
              <div className="px-2">
                <a
                  href="https://www.facebook.com/migobucks"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ cursor: "pointer" }}
                >
                  <FaFacebook />
                </a>
              </div>
              <div className="px-2">
                <a
                  href="https://www.linkedin.com/company/migobucks/"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ cursor: "pointer" }}
                >
                  <FaLinkedin />
                </a>
              </div>
              <div className="px-2">
                <a
                  href="https://twitter.com/codingeeknet"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ cursor: "pointer" }}
                >
                  <FaTwitter />
                </a>
              </div>
              <div className="px-2">
                <a
                  href="https://wa.me/9037243199"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ cursor: "pointer" }}
                >
                  <FaWhatsapp />
                </a>
              </div>
            </div>
          </div>
          <div className="flex flex-col  ">
            <h1 className="text-white text-2xl font-semibold mb-1">
              RESOURCES
            </h1>
            <div style={{ paddingBottom: "10px" }}>
              <a
                href="https://www.migobucks.com/app"
                target="_blank"
                className="text-white no-underline"
              >
                Migobucks App
              </a>
            </div>
            <div style={{ paddingBottom: "10px" }}>
              <a
                href="https://www.migobucks.com/buddy"
                target="_blank"
                className="text-white no-underline"
              >
                Migobucks Buddy
              </a>
            </div>
            <div style={{ paddingBottom: "10px" }}>
              <a
                href="https://www.brand.migobucks.com/"
                target="_blank"
                className="text-white no-underline"
              >
                Migobucks Brand
              </a>
            </div>
            <div style={{ paddingBottom: "10px" }}>
              <a
                href="https://www.sokoskills.com/"
                target="_blank"
                className="text-white no-underline"
              >
                Sokoskills
              </a>
            </div>
          </div>
          <div className="flex flex-col">
            <h1 className="text-white text-2xl font-semibold mb-1">About</h1>
            <div>
              <a href="#" className="text-white no-underline">
                Who we are
              </a>
            </div>
            <div>
              <a href="/careers" className="text-white no-underline">
                Career
              </a>
            </div>
            <div>
              <a
                href="https://www.sokoskills.com/blog"
                target="_blank"
                className="text-white no-underline"
              >
                Blog
              </a>
            </div>

            <div>
              <a href="/" className="text-white no-underline">
                Upcoming Events
              </a>
            </div>
          </div>
        </div>

        <div className="flex  justify-around">
          <div className="text-white">
            <a
              href="https://www.facebook.com/migobucks"
              target="_blank"
              rel="noopener noreferrer"
              style={{ cursor: "pointer" }}
            >
              <FontAwesomeIcon icon="fa-brands fa-facebook" />
            </a>
          </div>
          <div className="text-white">
            <FontAwesomeIcon icon="fa-brands fa-twitter" />
          </div>
        </div>
      </footer>
    </>
  )
}

export default Footer
