import React from "react"
import { Link } from "gatsby"
import "./navbar.css"
import Logo from "../images/logo.svg"
import { NAVBAR_MENUS } from "../utils/constants"
const Navbar = () => {
  return (
    <nav className="bg-transparent">
      <div className="max-w-7xl mx-auto px-8 py-5">
        <div className="flex items-center justify-between h-16">
          <div className="w-full justify-between flex items-center">
            <div style={{ marginTop: "20px" }}>
              <img src={Logo} alt="Logo" />
            </div>

            <div className="hidden md:block">
              <div className="ml-10 flex items-baseline space-x-4">
                {NAVBAR_MENUS.menuItems.map(menuItem => (
                  <div key={menuItem.label} className="relative group">
                    {menuItem.link?.startsWith("http") ? (
                      <a
                        style={{ whiteSpace: "nowrap" }}
                        href={menuItem.link}
                        className="text-white opacity-50 px-3 py-2 rounded-md text-sm font-medium font-poppins"
                        target="_blank" // Open in a new tab
                        rel="noopener noreferrer" // Recommended for security
                      >
                        {menuItem.label}
                      </a>
                    ) : (
                      <Link
                        to={menuItem.link}
                        className="text-white opacity-50 px-3 py-2 rounded-md text-sm font-medium font-poppins"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        {menuItem.label}
                      </Link>
                    )}
                    {menuItem.subMenu && (
                      <div className="absolute hidden mt-2 space-y-2 bg-white text-black px-2 py-2 rounded-md shadow-lg group-hover:block">
                        {menuItem.subMenu.map(subMenuItem => (
                          <div
                            key={subMenuItem.label}
                            className="relative group"
                          >
                            {subMenuItem.link.startsWith("http") ? (
                              <a
                                style={{ whiteSpace: "nowrap" }}
                                href={subMenuItem.link}
                                className="text-black opacity-50 px-2 py-1 rounded-md text-sm font-medium font-poppins"
                                target="_blank" // Open in a new tab
                                rel="noopener noreferrer" // Recommended for security
                              >
                                {subMenuItem.label}
                              </a>
                            ) : (
                              <Link
                                style={{ whiteSpace: "nowrap" }}
                                to={subMenuItem.link}
                                className="text-black opacity-50 px-2 py-1 rounded-md text-sm font-medium font-poppins"
                              >
                                {subMenuItem.label}
                              </Link>
                            )}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="-mr-2 flex md:hidden">
            <button className="text-gray-800 dark:text-white hover:text-gray-300 inline-flex items-center justify-center p-2 rounded-md focus:outline-none">
              <svg
                width="20"
                height="20"
                fill="white"
                className="h-8 w-8"
                viewBox="0 0 1792 1792"
                xmlns="http://www.w3.org/2000/svg"
              >
                {/* Your SVG icon */}
              </svg>
            </button>
          </div>
        </div>
      </div>
    </nav>
  )
}

export default Navbar
